import React from "react";



const Content = ({ styles }) => {

  const dummyPostTop = {
    title: `🅱️OTTOM`,
  };

  const dummyPostBottom = {
    title: `🅱️EXT`,
  };


  const contentStyle = {
    textAlign: 'center',
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  };

  return (
    <div style={contentStyle}>
      <h1>{dummyPostTop.title}</h1>
      <h1>{dummyPostBottom.title}</h1>
    </div>
  );
};

export default Content;
